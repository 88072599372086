<template>

    <nav class="uk-navbar-container" uk-navbar style="z-index: 99">

        <div class="uk-navbar-center">
           <a class="uk-navbar-item uk-logo" href="/">{{translate('sgirot.general.systemTitle')}} - {{$store.getters['user/profile'].fullName}}</a>
        </div>
    </nav>
    <div class="dashboard-layout uk-flex uk-flex-between">
        <div class="sidebar">
            <side-nav></side-nav>
        </div>

        <main class="main uk-container uk-container-expand " style="width: 100%;">
            <admin-notifications></admin-notifications>
            <slot></slot>
        </main>

    </div>

</template>

<script>
    import SideNav from '@/client/applications/sgirot/components/nav/SideNav.vue'
    import AdminNotifications from '@/client/applications/sgirot/components/etc/AdminNotifications.vue'
    export default {
        props: {

        },
        components: {
            SideNav, AdminNotifications
        },
        data () {
            return {
                localNavItems: {
                    0: {
                        text: 'פרוייקטים',
                        icon: 'list',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'index'},
                        children: false,
                    },
                    1: {
                        text: 'מומחים',
                        icon: 'users',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'index'},
                        children: false,
                    },
                    3: {
                        text: 'רשימת מקורות',
                        icon: 'album',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'index'},
                        children: false,
                    },
                    5: {
                        text: 'סיכום',
                        icon: 'calendar',
                        notifications: 0,
                        action: 'routerNavigation',
                        actionData: {name: 'index'},
                        children: false,
                    },
                }
            }
        }
    }
</script>

<style lang="scss" scoped >
   // $sidebar-width : 220px;
    $sidebar-width : 50px;
    .sidebar {
        min-width: $sidebar-width;
        width: $sidebar-width;
        flex: $sidebar-width;

        @media (max-width: 767px) {
            position: fixed;
            inset-inline-start: 0;
            top: 60px;
            height: calc(100vh - 60px);
            z-index: 2;
        }
    }

    .main {
        flex: calc(100% - #{$sidebar-width});
        max-width: calc(100% - #{$sidebar-width});
        padding: calc(var(--global-margin) * 2);
        box-sizing: border-box;

        @media (max-width: 767px) {
            flex: 1 1 100%;
            padding-inline-start: 40px;
            padding-inline-end: 0;
            padding-top: 0;
            max-width: 100%;
            margin: 0;

        }
    }
</style>
