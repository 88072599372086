<template>
    <div class="admin-layout-wrapper">
        <nav class=" admin-navbar uk-navbar-container  uk-background-default uk-box-shadow-medium uk-flex "   uk-navbar>
            <div class="uk-navbar-left">

                <a href="#"
                   @click.prevent=""
                   class="uk-navbar-item force-show menu-toggle"
                   uk-toggle="target: .uk-navbar-item.menu-toggle, .side-menu-container; animation: uk-animation-fade"
                   hidden>
                    <inline uk-icon="menu"></inline>
                </a>

                <a href="#"
                   @click.prevent=""
                   class="uk-navbar-item force-show menu-toggle"
                   uk-toggle="target: .uk-navbar-item.menu-toggle, .side-menu-container; animation: uk-animation-fade">
                    <inline uk-icon="close"></inline>
                </a>

                <router-link :to="{path: '/'}" class="uk-navbar-item">
                    <span class="uk-icon"
                          uk-icon="home"
                          :uk-tooltip="translateTitleCase('admin.menu.applicationHomeTooltip')"></span>
                </router-link>

                <div class="uk-navbar-item">
                    <span class="uk-icon"
                          uk-icon="search"
                          :uk-tooltip="translateTitleCase('admin.menu.searchTooltip')"
                          uk-toggle="target: .uk-navbar-item:not(.force-show); animation: uk-animation-fade"
                    ></span>
                </div>

            </div>
            <div class="uk-navbar-right">
                <div class="uk-navbar-item">
                    <div class="admin-notification-widget">
                        <span class="notification-icon" uk-icon="bell">
                            <span class="notification-badge uk-badge">2</span>
                        </span>
                    </div>
                </div>
                <div class="uk-navbar-item">
                    <div class="user-widget-loading" v-if="! isloggedStatusKnown">
                        <spinner :inline="true" :text="safeTranslate('user.stateLoading')"></spinner>
                    </div>
                    <div class="user-widget" v-if="isloggedStatusKnown">
                        <router-link tag="a" v-if=" ! isLoggedIn" :to="{name: 'user-login'}">Login</router-link>

                        <a v-if="isLoggedIn" @click="logout">
                            <span class="uk-icon uk-margin-small-right" uk-icon="sign-out"></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="uk-navbar-item" hidden>
                    <span class="uk-icon"
                          uk-icon="close"
                          :uk-tooltip="translateTitleCase('admin.menu.searchTooltip')"
                          uk-toggle="target: .uk-navbar-item:not(.force-show); animation: uk-animation-fade"></span>
            </div>
            <inline class="uk-navbar-item uk-width-expand" hidden>
                <form-input
                        name="search"
                        label=""
                        :showLabel="false"
                        placeholder="core.searchPlaceholder"
                        type="text"
                        icon="search"
                        size="small"
                        class="uk-width-expand"
                        :wrapperMargins="false"
                ></form-input>
            </inline>


        </nav>


        <div class=" uk-background-muted admin-outer-grid " uk-grid>
            <div class="uk-container side-menu-container uk-container-small uk-background-secondary uk-light uk-box-shadow-xlarge">
                <div class="side-menu">
                    <ul class="uk-nav">

                        <a class="uk-navbar-item uk-margin-top uk-margin-small-bottom uk-logo" href="#" :style="logoStyle"></a>

                        <li v-for="link of links"
                            :key="link"
                            :link="link">
                            <router-link
                                    :to="link.to">
                                <inline class=" uk-margin-small-left uk-margin-small-right" :uk-icon="link.icon"></inline>
                                <span>{{safeTranslate(link.shortLabel)}}</span>
                            </router-link>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="main-content-wrapper uk-width-expand uk-margin-large-bottom">
                <slot name="default"></slot>
            </div>
        </div>



    </div>
</template>

<script>
    import {computed, watchEffect} from 'vue'
    export default {
        props: {
            'defaultComponent' : String,
            logo: {
                type: String,
                default: 'logos/main.png'
            }
        },
        data: function () {
            return {
                links: [
                    {
                        to: {name:'admin-dashboard'},
                        icon: 'settings',
                        shortLabel: 'admin.dashboard.titleShort',
                        label: 'admin.dashboard.title',
                        description: 'admin.dashboard.description',
                    },
                    {
                        to: {name:'entity-dashboard'},
                        icon: 'copy',
                        shortLabel: 'admin.entity.titleShort',
                        label: 'admin.entity.title',
                        description: 'admin.entity.description',
                    },
                    {
                        to: {name: 'admin-user'},
                        icon: 'user',
                        shortLabel: 'admin.user.titleShort',
                        label: 'admin.user.title',
                        description: 'admin.user.description',
                    },
                    {
                        to: {name:'admin-language'},
                        icon: 'world',
                        shortLabel: 'admin.language.titleShort',
                        label: 'admin.language.title',
                        description: 'admin.language.description',
                    },
                    {
                        to: {name:'admin-config'},
                        icon: 'cog',
                        shortLabel: 'admin.config.titleShort',
                        label: 'admin.config.titleShort',
                        description: 'admin.config.description',
                    }
                ]
            };
        },
        computed: {
            isloggedStatusKnown () {
                return computed(() =>this.$store.getters['user/isTokenValid'] !== null);
            },
            isLoggedIn () {
                return computed(() => this.$store.getters['user/isTokenValid']);
            },
            logoStyle () {
                let url = utilities.requireAsset('images/' + this.logo);
                return {
                    backgroundImage : "url('"+url+"')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center 5px',
                    height:         '78px',
                    minHeight:      '0',
                    backgroundSize: '60px auto',
                };
            }

        },
        methods: {
            logout() {
                this.$store.commit('user/invalidateAuth');
                this.$store.commit('user/clearProfile');
                this.$router.push('/');
            }
        },
        mounted () { }

    }
</script>

<style scoped lang="scss">


    .admin-layout-wrapper{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .admin-outer-grid {
        flex-direction: row;
        flex: 100%;
    }
    //$global-inverse-color
    .side-menu-container {
        width: 180px;
        padding-inline-end: 0;
    }

    .admin-navbar {
        background: var(--global-background);
    }



    .side-menu {
        width: 100%;
        flex: 100%;

        .uk-nav a:not(.uk-logo) {
            position: relative;
            padding-inline-start: calc(var(--global-margin) * 0.25);
            padding-inline-end: calc(var(--global-margin) * 0.25);

            &.uk-active, &:hover {

                &:before {
                    position: absolute;
                    content: ' ';
                    width: 2px;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: var(--global-primary-background);
                }
            }
        }
    }

    .admin-notification-widget {
        .notification-icon {
            position: relative;
            cursor: pointer;

            .notification-badge {
                position: absolute;
                top: -70%;
                right: -50%;
                z-index: 2;
            }
        }
    }
</style>
