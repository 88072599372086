<template>

    <block class="admin-dashboard-view" :class="{'rtl' :isLanguageRtl()}">
        <div class="uk-container  uk-container-expand uk-flex uk-flex-between uk-padding  uk-flex-wrap">
            <div class="title-wrapper uk-margin-medium-right uk-position-relative">
                <h3 class="uk-margin-remove uk-flex uk-flex-middle">
                    <div class=" title-back-icon-wrap uk-margin-small-right uk-text-inverse-color uk-background-primary "
                         :uk-tooltip="translate('core.goBack')"

                         v-if="showTitleBackLink"
                         style="border-radius: 100%;padding: 2px; cursor: pointer; "
                        @click="goBack()">
                        <span class="title-back-icon"
                              :class="{'rtl' :isLanguageRtl()}"
                              uk-icon="icon: chevron-left;ratio:2" ></span>
                    </div>
                    <div class="title-wrapper">
                        <slot name="title">{{translateTitleCase('admin.dashboard.defaultTitle')}}</slot>
                    </div>
                </h3>
            </div>

            <div class="breadcrumbs-wrapper uk-flex uk-flex-middle">
                <slot name="breadcrumbs">
                    <breadcrumbs></breadcrumbs>
                </slot>
            </div>


        </div>

        <div class="uk-container uk-container-expand">
            <slot name="mainContent">
                <div class="uk-card uk-card-default">
                    <div v-if="showToolbar" class="admin-view-toolbar ">
                        <!-- using slot toolbar overwrites the whole thing. consumer can also just use toolbarStart /end to use our premade layout-->
                        <slot name="toolbar">
                            <block class="actions uk-flex uk-flex-between">
                                <block class="actions__start uk-flex">
                                    <slot name="toolbarStart"></slot>
                                </block>
                                <block class="actions__end uk-flex">
                                    <slot name="toolbarEnd"></slot>
                                </block>
                            </block>

                        </slot>
                    </div>

                    <div class="uk-card-body">
                        <slot name="default"></slot>
                    </div>

                </div>
            </slot>

        </div>

    </block>



</template>

<script>
    import {computed, watchEffect} from 'vue'
    import { useMeta } from 'vue-meta'
    import {useI18n} from "vue-i18n/index";

    export default {
        setup () {
            let {t} = useI18n();
            let {meta} = useMeta( {
                'sitename': {tag: 'meta', 'name':'sitename', content: t('core.meta.adminSiteName')},
            });


            return {meta};
        },
        props: {
            showTitleBackLink : {
                type: Boolean,
                default: true,
            },
            showToolbar : {
                type: Boolean,
                default: true
            },
            backRoute: {
                type: [Object, Boolean],
                default: false,
            }
        },
        data: function () {
            return {

            };
        },
        computed: {

        },
        methods: {
            goBack() {
                if (this.backRoute) {
                    this.$router.push(this.backRoute);
                } else {
                    this.$router.back();
                }
            }
        },
        mounted () {


        }

    }
</script>


<style scoped lang="scss">
    .main-content-wrapper {
        padding: 25px 40px;
    }

    .admin-view-toolbar {
        padding-top: calc(var(--global-margin) * 2);
        padding-left: calc(var(--global-margin) * 2);
        padding-right: calc(var(--global-margin) * 2);
    }

    .rtl .title-wrapper {
        margin-right: 0!important;
        margin-left: calc(var(--global-margin) * 2)!important;
    }
    .rtl .title-back-icon-wrap {
        margin-right: 0!important;
        margin-inline-end: calc(var(--global-margin) * 0.2)!important;
    }
    .rtl .title-back-icon {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);

    }



</style>
