<template>
    <block v-if="isSuperAdmin">
        <div class="notification-list" v-if="notifications && notifications.length > 0">
            <div class="uk-alert-danger" uk-alert
                 v-if="notificationsByType && notificationsByType['noPriceProject']">
                <a class="uk-alert-close" uk-close></a>
                <h3>
                    <strong>{{translate('sgirot.notifications.alertTitle')}}</strong>
                </h3>
                <span class="uk-display-block">
                    {{translate('sgirot.notifications.noPriceProjectTitle', {count: notificationsByType['noPriceProject'].length})}}
                </span>
                <a href="#" @click.prevent="startHandleNoPriceProject">{{translate('sgirot.notifications.alertCta')}}</a>
            </div>
            <div class="uk-alert-danger" uk-alert
                 v-if="notificationsByType && notificationsByType['expertDebt']">
                <a class="uk-alert-close" uk-close></a>
                <h3>
                    <strong>{{translate('sgirot.notifications.alertTitle')}}</strong>
                </h3>
                <span class="uk-display-block">
                    {{translate('sgirot.notifications.expertDebtTitle', {count: notificationsByType['expertDebt'].length})}}
                </span>
                <a href="#" @click.prevent="startHandleExpertDebt">{{translate('sgirot.notifications.alertCta')}}</a>
            </div>



        </div>

    </block>
</template>

<script>


import {useStore} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';


export default {

    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        return {asyncOps, asyncOpsReady, asyncStatus};
    },
    data () {
        return {
            notifications: false,
            asyncData: {
                notifications: 'notifications'
            }
        }
    },
    computed : {
        notificationsByType () {
            if ( ! this.notifications || this.notifications.length < 1) {
                return false;
            }

            let byType = {};
            this.notifications.forEach (item => {
                if ( ! Array.isArray(byType[item.type]) ) {
                    byType[item.type] = [];
                }

                byType[item.type].push(item);
            })

            return byType;
        }
    },
    methods: {
        startHandleNoPriceProject () {
            this.$s.ui.notification(this.translate('sgirot.notifications.showingProjectsWithNoPrice'));
            this.$router.push({name: 'index', params: {noPriceOnly: true}});
        },
        startHandleExpertDebt () {
            this.$router.push({name: 'expert'});
        }
    }

};
</script>

<style scoped lang="scss" >

</style>
