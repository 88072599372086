const cookies = require('js-cookie');
import _ from 'lodash/object';

const plugin =  {
    that :this,
    defaultConfig : {
        expires : Number(config.cookie.expires),
        path    : config.cookie.path
    },
    install(Vue, options) {
        let  { set, get, remove, defaultConfig} = plugin;
        plugin.defaultConfig = _.merge(defaultConfig, options);

        Vue.config.globalProperties.$cookie = {set, get, remove, defaultConfig};
    },
    set (key, value, config = plugin.defaultConfig) {
        return cookies.set(key, value, config);
    },
    get (key, defaultValue = null) {
        let val =  cookies.get(key);
        if (typeof val === 'undefined') {
            return defaultValue;
        }

        if (typeof val === 'string') {
            try {
                let result = JSON.parse(val);
                if (result) {
                    return result;
                }
            } catch (e) {

            }
        }

        return val;
    },
    remove (name) {
        return cookies.remove(name);
    },
    delete (name) {
        return cookies.remove(name);
    }
};



export default {
    install : plugin.install
}
